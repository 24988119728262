import React, { type FC } from 'react';
import { List } from 'bb/ui';
import { ListItem } from 'bb/ui/List';
import { MaxWidthContainer } from '../Containers';
import { FaqListItem } from './FaqListItem';
import { type FaqProps } from './types';

export const FaqList: FC<FaqProps> = ({ faq }) => (
    <MaxWidthContainer maxWidth={800}>
        <List spacing={5}>
            {faq?.[0]?.items?.map((q) => (
                <ListItem key={q.question}>
                    <FaqListItem item={q} />
                </ListItem>
            ))}
        </List>
    </MaxWidthContainer>
);
