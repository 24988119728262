import React from 'react';
import { Text, useDaysPeriod, type TextProps } from 'bb/i18n';

export type TrialDaysTextProps = {
    trialDays: number | null;
} & TextProps<'span'>;

export const TrialDaysText = ({
    trialDays,
    ...restProps
}: TrialDaysTextProps) => {
    const { period } = useDaysPeriod(trialDays);

    return period ? (
        <Text
            t="cta:trial"
            values={{ period }}
            as="span"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...restProps}
        />
    ) : (
        <Text
            t="cta:fallback"
            as="span"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...restProps}
        />
    );
};
