'use client';

/* eslint-disable react/jsx-props-no-spreading */
import React, { type MutableRefObject, useRef, type JSX } from 'react';
import classnames from 'classnames';
import { Text, type TextProps } from 'bb/i18n';
import { type PolymorphicComponent } from 'bb/ui';
import { useOnScreen } from 'bb/ui/hooks';
import css from './bookBeatHeader.module.scss';

export type BookBeatHeaderAnimatedChildrenProps<
    TElementType extends React.ElementType = 'div'
> = PolymorphicComponent<
    TElementType,
    {
        children: React.ReactNode;
        className?: string;
        /**
         * Sometimes the children are passed as inline elements
         * broken up by a <br> element. In those cases we need
         * to pass inlineChildren={true} in order for them
         * to align properly.
         */
        inlineChildren?: boolean;
    } & TextProps<TElementType>
>;

export const BookBeatHeaderAnimatedChildren = ((
    props: BookBeatHeaderAnimatedChildrenProps<'div'>
) => {
    const {
        children,
        className,
        as = 'div',
        inlineChildren = false,
        ref: passedRef,
        ...restProps
    } = props;
    const rootRef = useRef<HTMLElement>(null);

    const [isIntersecting] = useOnScreen(rootRef, 0.2);

    return (
        <Text
            as={as}
            className={classnames(
                css.animated,
                isIntersecting && css.show,
                className,
                inlineChildren && css.inlineChildren
            )}
            ref={(textRef: HTMLDivElement) => {
                // Silence ts which complains about setting
                // passed ref explicitly.
                (rootRef as unknown as MutableRefObject<HTMLElement>).current =
                    textRef;

                if (passedRef) {
                    // Same as above
                    // eslint-disable-next-line no-param-reassign
                    (passedRef as MutableRefObject<HTMLDivElement>).current =
                        textRef;
                }
            }}
            {...restProps}
        >
            {children}
        </Text>
    );
}) as <TElementType extends React.ElementType = 'div'>(
    props: BookBeatHeaderAnimatedChildrenProps<TElementType>
) => JSX.Element;
