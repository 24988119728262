'use client';

import React, { type FC } from 'react';
import { ExternalLink } from 'bb/common/ExternalLink';
import { Text } from 'bb/i18n/Text';
import { useTranslation } from 'bb/i18n/translationUtils';

export const FaqText = () => {
    const { t } = useTranslation(['faq']);
    return (
        <Text t="faq:faqTextWithLink" center>
            <ExternalLink target="_blank" href={t('faq:faqLink')} />
        </Text>
    );
};

interface FaqTextStudentProps {
    pdfLink: string;
}
export const FaqTextStudent: FC<FaqTextStudentProps> = ({ pdfLink }) => {
    const { t } = useTranslation(['student', 'studentV2']);
    return (
        <Text t="student:studentFaqInfo" center>
            <ExternalLink target="_blank" href={t('studentV2:faqLink')} />
            <ExternalLink target="_blank" href={pdfLink} />
        </Text>
    );
};
